/* Only demo */
@media (max-width: 1000px) {
  .welcome-message {
    display: none;
  }
}

.clear-view {
  #page-wrapper {
    margin-top: 50px;
    margin-left: 0px;
  }
}

.fa-stack {
  color: $act-gray;
}

.row-actions {
  display: block;
  margin-top: 5px;
}

a:focus, :focus {
  outline-style: none;
}

.filter-toggle-buttons {

  .btn-filter.btn-outline:not(.active):focus, .btn-filter.btn-outline {
    color: #676a6c;
    border-color: #e5e6e7;
    background-color: transparent;
    margin-right: 10px;
    overflow: hidden;
  }

  .btn-filter:hover, .btn-filter:focus, .btn-filter:active, .btn-filter.active, .btn-filter:active:hover, .btn-filter.active:hover {
    background-color: darken($lazur, 3%);
    border-color: darken($lazur, 3%);
    color: #FFFFFF;
  }

  .btn-filter:active, .btn-filter.active, .open .dropdown-toggle.btn-filter {
    background-image: none;
  }

  .btn-filter.disabled, .btn-filter.disabled:hover, .btn-filter.disabled:focus, .btn-filter.disabled:active, .btn-filter.disabled.active, .btn-filter[disabled], .btn-filter[disabled]:hover, .btn-filter[disabled]:focus, .btn-filter[disabled]:active, .btn-filter.active[disabled], fieldset[disabled] .btn-filter, fieldset[disabled] .btn-filter:hover, fieldset[disabled] .btn-filter:focus, fieldset[disabled] .btn-filter:active, fieldset[disabled] .btn-filter.active {
    background-color: lighten($lazur, 4%);
    border-color: lighten($lazur, 4%);
  }

}

@media (max-width: 767px) {
  .filter-toggle-buttons {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 0;
    margin: 0px -15px;

    .btn-filter {
      margin: 5px 10px;
      width: calc(1/2*100% - (1 - 1/2)*40px);
    }
  }


}
.clickable {
  cursor: pointer;
}

.row.vertical-align {
  display: flex;
  align-items: center;
}
/* Extra small devices (767px and down) */
@media (max-width: 767px) {
  .row.vertical-align {
    display: block; /* Turn off the flexible box layout */
  }
}

#category-list {
  .dd-handle {
    height: 37px;
    line-height: 24px;
    &:hover {
      color: $sib-gold;
    }
  }
}
.table.widget-list {
  tbody {
    tr {
      td {
        line-height: 2.4;
      }
    }
  }
}


[v-cloak] {
  display: none;
}

.el-select__tags {
  .el-tag--primary {
    background-color: rgba(171,151,107,.1);
    border-color: rgba(171,151,107,.2);
    color: #ab976b;
  }
}