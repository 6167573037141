/*
    Common
*/

.wizard,
.tabcontrol
{
  display: block;
  width: 100%;
  margin-bottom: 50px;
  //overflow: hidden;
}

.wizard a,
.tabcontrol a
{
  color: #aaa;
  outline: 0;
}

.wizard ul[role=tablist],
.wizard ul[role=menu],
.tabcontrol ul[role=tablist],
.tabcontrol ul[role=menu]
{
  list-style: none !important;
  padding: 0;
  padding-left: 20px;
  margin: 0;
}

.wizard ul[role=tablist],
.wizard ul[role=menu] > li,
.tabcontrol ul[role=tablist],
.tabcontrol ul[role=menu] > li
{
  li[role=tab] {
    position: relative;
  }
  display: block;
  padding: 0;
}

/* Accessibility */
.wizard > .steps .current-info,
.tabcontrol > .steps .current-info
{
  position: absolute;
  left: -999em;
}

.wizard > .content > .title,
.tabcontrol > .content > .title
{
  position: absolute;
  left: -999em;
}



/*
    Wizard
*/

.wizard > .steps
{
  .label {
    position: absolute;
    top: -16px;
    right: -7px;
  }
  .badge {
    position: absolute;
    top: 21px;
    left: 19px;
  }
  background: #ffffff;
  border-top: 4px solid $border-color;
  height: 80px;
  position: relative;
  display: block;
  width: 100%;
}

.wizard > .content .ibox:last-of-type {
  margin-bottom: 0px;
}
.wizard.vertical > .steps
{
  display: inline;
  float: left;
  width: 30%;
}

.wizard > .steps > ul > li
{
  margin: -2px 15px;
}

.wizard > .steps > ul > li,
.wizard > .actions > ul > li
{
  float: left;
}

.wizard > .steps .numberCircle {
  border-radius: 50%;
  width: 30px;
  font-size: 14px;
  background: #eaeaea;
  display: inline-block;
  color: #fff;
  margin-right: 5px;
}
.numberCircle span.number {
  text-align: center;
  line-height: 30px;
  display: block;
}

.wizard > .steps a,
.wizard > .steps a:hover,
.wizard > .steps a:active
{
  color: #979797;
  font-size: 14px;
  margin-top: 30px;
  display: block;
  width: auto;
  text-decoration: none;

}

.wizard > .steps .disabled a,
.wizard > .steps .disabled a:hover,
.wizard > .steps .disabled a:active
{
  color: #eaeaea;
  cursor: default;
}

.wizard > .steps .current .numberCircle {
  background: $sib-gold;
}
.wizard > .steps .current a,
.wizard > .steps .current a:hover,
.wizard > .steps .current a:active
{
  color: #979797;
  cursor: default;
}

.wizard > .steps .done a,
.wizard > .steps .done a:hover,
.wizard > .steps .done a:active
{
}

.wizard > .steps .error a,
.wizard > .steps .error a:hover,
.wizard > .steps .error a:active
{
  background: $red;
  color: #fff;
}

.wizard > .content
{
  display: block;
  margin: 0px;
  margin-top: 5px;
  min-height: 120px;
  //overflow: hidden;
  position: relative;
  width: auto;
  background-color: #ffffff;

}

.wizard-big.wizard > .content {
  min-height: 320px;
}

.wizard > .content > .body > iframe
{
  border: 0 none;
  width: 100%;
  height: 100%;
}


.wizard > .content > .body input.error
{
  background: rgb(251, 227, 228);
  border: 1px solid #fbc2c4;
  color: #8a1f11;
}

.wizard > .content > .body label
{
  display: inline-block;
  margin-bottom: 0.5em;
}

.wizard > .content > .body .checkbox-inline
{
  label, + label {
    margin-bottom: 0px;
  }

}

.wizard > .content > .body label.error
{
  color: #8a1f11;
  display: inline-block;
  margin-left: 1.5em;
}

.wizard > .actions
{
  position: relative;
  display: block;
  text-align: right;
  width: 100%;
  border-top: 2px solid #edeff0;
  background-color: #ffffff;
  padding: 30px;
}

.wizard > .actions > ul
{
  display: inline-block;
  text-align: right;
}

.wizard > .actions > ul > li
{
  margin: 0 0.5em;
}

.wizard.vertical > .actions > ul > li
{
  margin: 0 0 0 1em;
}

.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions a:active
{

  background-color: $sib-gold;
  display: block;
  padding: 8px 20px;
  text-decoration: none;
  color: #fff;
}

.wizard > .actions > ul > li.cancel a,
.wizard > .actions > ul > li.cancel a:hover,
.wizard > .actions > ul > li.cancel a:active
{
  background-color: #eee;
  color: #aaa;
}

.wizard > .actions .disabled a,
.wizard > .actions .disabled a:hover,
.wizard > .actions .disabled a:active
{
  background-color: #eee;
  color: #aaa;
}

.wizard > .loading
{
}

.wizard > .loading .spinner
{
}


.steps-initial-legend {
  margin-top: -15px;
}
/*
    Tabcontrol
*/

.tabcontrol > .steps
{
  position: relative;
  display: block;
  width: 100%;
}

.tabcontrol > .steps > ul
{
  position: relative;
  margin: 6px 0 0 0;
  top: 1px;
  z-index: 1;
}

.tabcontrol > .steps > ul > li
{
  float: left;
  margin: 5px 2px 0 0;
  padding: 1px;

  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.tabcontrol > .steps > ul > li:hover
{
  background: #edecec;
  border: 1px solid #bbb;
  padding: 0;
}

.tabcontrol > .steps > ul > li.current
{
  background: #fff;
  border: 1px solid #bbb;
  border-bottom: 0 none;
  padding: 0 0 1px 0;
  margin-top: 0;
}

.tabcontrol > .steps > ul > li > a
{
  color: #5f5f5f;
  display: inline-block;
  border: 0 none;
  margin: 0;
  padding: 10px 30px;
  text-decoration: none;
}

.tabcontrol > .steps > ul > li > a:hover
{
  text-decoration: none;
}

.tabcontrol > .steps > ul > li.current > a
{
  padding: 15px 30px 10px 30px;
}

.tabcontrol > .content
{
  position: relative;
  display: inline-block;
  width: 100%;
  height: 35em;
  overflow: hidden;
  border-top: 1px solid #bbb;
  padding-top: 20px;
}

.tabcontrol > .content > .body
{
  float: left;
  position: absolute;
  width: 95%;
  height: 95%;
  padding: 2.5%;
}


.arrow_steps {
  margin-left: 0px;
  margin-right: 0px;
  div {
    padding: 20px 45px 20px;
    position: relative;
    font-size: 20px;
    color: #707070;
    width: 24.75%;
    margin-left: 0.25%;
    &:first-child {
      width: 25%;
      margin-left: 0px;
    }
    background: #EBEBEB;
    &:first-child:before,
    &:last-child:after {
      border: none;
    }
    &:before {
      width: 0;
      height: 0;
      border-top: 30px inset transparent;
      border-bottom: 30px inset transparent;
      border-left: 30px solid #FFFFFF;
      position: absolute;
      content: "";
      top: 0;
      left: 0;
    }

    &:after {
      width: 0;
      height: 0;
      border-top: 30px inset transparent;
      border-bottom: 30px inset transparent;
      border-left: 30px solid #EBEBEB;
      position: absolute;
      content: "";
      top: 0;
      right: -30px;
      z-index: 2;
    }
    &.active {
      color: #ffffff;
      background: $sib-gold;
    }
  }
}