@font-face {
  font-family: 'okContaxPro75Bold';
  src: url('/fonts/2E56B8_0_0.eot');
  src: url('/fonts/2E56B8_0_0.eot?#iefix') format('embedded-opentype'), url('/fonts/2E56B8_0_0.woff2') format('woff2'), url('/fonts/2E56B8_0_0.woff') format('woff'), url('/fonts/2E56B8_0_0.ttf') format('truetype');
}
@font-face {
  font-family: 'okContaxPro45Lig';
  src: url('/fonts/2E56B8_1_0.eot');
  src: url('/fonts/2E56B8_1_0.eot?#iefix') format('embedded-opentype'), url('/fonts/2E56B8_1_0.woff2') format('woff2'), url('/fonts/2E56B8_1_0.woff') format('woff'), url('/fonts/2E56B8_1_0.ttf') format('truetype');
}
@font-face {
  font-family: 'okContaxPro65Med';
  src: url('/fonts/2E56B8_2_0.eot');
  src: url('/fonts/2E56B8_2_0.eot?#iefix') format('embedded-opentype'), url('/fonts/2E56B8_2_0.woff2') format('woff2'), url('/fonts/2E56B8_2_0.woff') format('woff'), url('/fonts/2E56B8_2_0.ttf') format('truetype');
}
@font-face {
  font-family: 'okContaxPro';
  src: url('/fonts/2E56B8_1_0.eot');
  src: url('/fonts/2E56B8_1_0.eot?#iefix') format('embedded-opentype'), url('/fonts/2E56B8_1_0.woff2') format('woff2'), url('/fonts/2E56B8_1_0.woff') format('woff'), url('/fonts/2E56B8_1_0.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'okContaxPro';
  src: url('/fonts/2E56B8_2_0.eot');
  src: url('/fonts/2E56B8_2_0.eot?#iefix') format('embedded-opentype'), url('/fonts/2E56B8_2_0.woff2') format('woff2'), url('/fonts/2E56B8_2_0.woff') format('woff'), url('/fonts/2E56B8_2_0.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}