.email-container {
  .mail_table {
   th, td {
      padding: 10px;
      border: 1px solid #dddddd;
    }

    th {
      background: #e6e6e6;
    }
  }
}

/* What it does: Remove spaces around the email design added by some email clients. */
/* Beware: It can remove the padding / margin and add a background color to the compose a reply window. */
body.email_body {
  margin: 0 auto !important;
  padding: 0 !important;
  height: 100% !important;
  width: 100% !important;


  /* What it does: Stops email clients resizing small text. */
  * {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  /* What it does: Centers email on Android 4.4 */
  div[style*="margin: 16px 0"] {
    margin: 0 !important;
  }

  /* What it does: forces Samsung Android mail clients to use the entire viewport */
  #MessageViewBody, #MessageWebViewDiv {
    width: 100% !important;
  }

  /* What it does: Stops Outlook from adding extra spacing to tables. */
  table,
  td {
    mso-table-lspace: 0pt !important;
    mso-table-rspace: 0pt !important;
  }

  /* What it does: Replaces default bold style. */
  th {
    font-weight: normal;
  }

  /* What it does: Fixes webkit padding issue. */
  table {
    border-spacing: 0 !important;
    border-collapse: collapse !important;
    margin: 0 auto !important;
  }

  .mail_table {
      th, td {
        padding: 10px !important;
        border: 1px solid #dddddd !important;
      }
      th {
        background: #e6e6e6 !important;
      }
  }

  /* What it does: Prevents Windows 10 Mail from underlining links despite inline CSS. Styles for underlined links should be inline. */
  a {
    text-decoration: none;
  }

  /* What it does: Uses a better rendering method when resizing images in IE. */
  img {
    -ms-interpolation-mode: bicubic;
  }

  /* What it does: A work-around for email clients meddling in triggered links. */
  a[x-apple-data-detectors], /* iOS */
  .unstyle-auto-detected-links a,
  .aBn {
    border-bottom: 0 !important;
    cursor: default !important;
    color: inherit !important;
    text-decoration: none !important;
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
  }

  /* What it does: Prevents Gmail from changing the text color in conversation threads. */
  .im {
    color: inherit !important;
  }

  /* What it does: Prevents Gmail from displaying a download button on large, non-linked images. */
  .a6S {
    display: none !important;
    opacity: 0.01 !important;
  }

  /* If the above doesn't work, add a .g-img class to any image in question. */
  img.g-img + div {
    display: none !important;
  }

  h2 {
    font-size: 22px;
    margin-top: 15px;
  }

  .button {
    display: inline-block !important;
    font-weight: bold !important;
    padding: 9px 17px !important;
    color: #FFFFFF !important;
    background-color: #343B45 !important;
    letter-spacing: 2px;
    text-decoration: none;
    font-family: Arial;
    margin-right: 10px;
  }

  .button-red {
    display: inline-block !important;
    font-weight: bold !important;
    padding: 7px 17px !important;
    color: #343B45 !important;
    border: 2px solid #343B45;
    background-color: #fff !important;
    letter-spacing: 2px;
    text-decoration: none;
    font-family: Arial;
    margin-right: 10px;
  }

  /* What it does: Removes right gutter in Gmail iOS app: https://github.com/TedGoas/Cerberus/issues/89  */
  /* Create one of these media queries for each additional viewport size you'd like to fix */

  /* iPhone 4, 4S, 5, 5S, 5C, and 5SE */
  @media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
    u ~ div .email-container {
      min-width: 320px !important;
    }
  }
  /* iPhone 6, 6S, 7, 8, and X */
  @media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
    u ~ div .email-container {
      min-width: 375px !important;
    }
  }
  /* iPhone 6+, 7+, and 8+ */
  @media only screen and (min-device-width: 414px) {
    u ~ div .email-container {
      min-width: 414px !important;
    }
  }


  /* Media Queries */
  @media screen and (max-width: 600px) {

    .email-container {
      width: 100% !important;
      margin: auto !important;
    }

    /* What it does: Forces table cells into full-width rows. */
    .stack-column,
    .stack-column-center {
      display: block !important;
      width: 100% !important;
      max-width: 100% !important;
      direction: ltr !important;
    }
    /* And center justify these ones. */
    .stack-column-center {
      text-align: center !important;
    }

    /* What it does: Generic utility class for centering. Useful for images, buttons, and nested tables. */
    .center-on-narrow {
      text-align: center !important;
      display: block !important;
      margin-left: auto !important;
      margin-right: auto !important;
      float: none !important;
    }
    table.center-on-narrow {
      display: inline-block !important;
    }

    /* What it does: Adjust typography on small screens to improve readability */
    .email-container p {
      font-size: 17px !important;
    }
  }
}