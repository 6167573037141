.communication-timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;

  &:before {
    background-color: #eee;
    bottom: 0;
    content: " ";
    left: 50px;
    margin-left: -1.5px;
    position: absolute;
    top: 0;
    width: 3px;
  }

  > li {
    margin-bottom: 20px;
    position: relative;

    &:before,
    &:after {
      content: " ";
      display: table;
    }

    &:after {
      clear: both;
    }

    > .communication-timeline-panel {
      border-radius: 2px;
      border: 1px solid #d4d4d4;
      box-shadow: 0 1px 2px rgba(100, 100, 100, 0.2);
      margin-left: 100px;
      padding: 20px;
      position: relative;

      .communication-timeline-heading {
        .communication-timeline-panel-controls {
          position: absolute;
          right: 8px;
          top: 5px;

          .timestamp {
            display: inline-block;
            padding-right: 15px;
            border-right: 1px solid #aaa;
          }

          .controls {
            display: inline-block;

            a {
              color: #999;
              font-size: 11px;
              padding: 0 5px;

              &:hover {
                color: #333;
                text-decoration: none;
              }
            }
          }

          .timestamp + .controls {
            padding-left: 5px;
          }
        }
      }
    }

    .communication-timeline-badge {
      color: #fff;
      font-size: 1.4em;
      height: 50px;
      left: 50px;
      line-height: 52px;
      margin-left: -25px;
      position: absolute;
      text-align: center;
      top: 16px;
      width: 50px;
      z-index: 100;

      .communication-timeline-direction {
        font-size: 0.7em;
        left: 16px;
        bottom: -32px;
      }
      .communication-timeline-direction.fa-sign-in {
        left: -14px;
      }

    }

    .communication-timeline-badge + .communication-timeline-panel {
      &:before {
        border-bottom: 15px solid transparent;
        border-left: 0 solid #ccc;
        border-right: 15px solid #ccc;
        border-top: 15px solid transparent;
        content: " ";
        display: inline-block;
        position: absolute;
        left: -15px;
        right: auto;
        top: 26px;
      }

      &:after {
        border-bottom: 14px solid transparent;
        border-left: 0 solid #fff;
        border-right: 14px solid #fff;
        border-top: 14px solid transparent;
        content: " ";
        display: inline-block;
        position: absolute;
        left: -14px;
        right: auto;
        top: 27px;
      }
    }
  }


  .communication-timeline-badge {
    &.primary {
      background-color: #2e6da4 !important;
    }

    &.success {
      background-color: #3f903f !important;
    }

    &.warning {
      background-color: #f0ad4e !important;
    }

    &.danger {
      background-color: #d9534f !important;
    }

    &.info {
      background-color: #5bc0de !important;
    }
  }

  .communication-timeline-title {
    margin-top: 0;
    color: inherit;
    &.communication-timeline-outbound-title {
      color: $sib-gold;
    }
  }

  .communication-timeline-body {
    > p,
    > ul {
      margin-bottom: 0;
    }

    > p + p {
      margin-top: 5px;
    }
    .communication-timeline-fullbody {
      margin-top: 20px;
      padding: 20px;
      border-top: 1px solid $border-color;
    }
  }
}