.booking_view {
  .request_status_actions {
    .panel-body {
      padding: 0px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .request_status_list {
    > div {
      padding: 0px;
      margin: 0px;
      padding-bottom: 50px;
      &:first-child {
        padding-left: 15px;
      }

      &:last-child {
        padding-right: 15px;
      }
    }

    ul {
      display: inline-block;
      list-style: none;
      width: 100%;
      background-color: $gray;
      font-size: 20px;
      padding-left: 0px;

      &.active {
        background-color: $sib-gold;
        color: #ffffff;
      }

      /** Main status **/
      li {
        text-align: center;
        height: 70px;
        span {
          display: block;
          height: 70px;
          line-height: 70px;
        }
        ul {
          color: $text-color;
          padding-left: 0px;
          display: inline-block;
          background-color: #ffffff;
          margin-top: 20px;
          /** Sub status **/
          li {
            border: 1px solid #000000;
            height: 100%;
            font-size: 16px;
            padding: 10px 20px;
            border-radius: 2px;
            margin: 10px 40px;
            text-align: center;
            &.active {
              color: #19b293;
              border-color: #19b293;
              background-color: #f3fbf9;
            }
            &.checked {
              color: $sib-gold;
              border-color: $sib-gold;
            }
          }
        }
      }
    }

  }

  .quick_actions {
    &.panel-default:last-of-type {
      margin-bottom: 20px;
    }
    //button, a.btn {
    //  margin-right: 15px;
    //}
    .quick_actions_buttons {
      a {
        margin-bottom: 5px;
        margin-right: 5px;
      }
    }
  }


  .villa-declined {
    .panel-heading {
      background-color: #eee;
    }
  }
}
.invoice-table {
  tfoot {
    text-align: right;
    border-top: 2px solid #e7eaec;
    tr {
      td:first-child {
        font-weight: bold;
      }
    }
  }
}

@media(max-width: 1199px) {
  .quick_actions {
    .quick_actions_buttons {
      display: flex;
      justify-content: space-around;
      margin-bottom: 15px;
      flex-wrap: wrap;
      a {
        flex-grow: 1;
        flex-shrink: 1;
      }
    }
  }
}