.act-btn {
  background: transparent;
  border: transparent;
  margin: 0px;
  padding: 0px;
  color: $act-gray;
}

table.dataTable {
  a {
    color: $sib-gold;
  }

  .label-default {
    background: none;
  }
  margin-bottom: 0px;

  tbody {
    tr {
      &.selected {
        background-color: $sib-gold;
      }
      .selected {
        background-color: $sib-gold;
      }
    }
  }
}

.page-size-selector-container {
  height: 20px;

  .label-default {
    background: none;
  }

}

.table-filter {
  .form-control:focus, .single-line:focus {
    border-color: #e5e6e7;
  }
}

.ibox-content {
  .dataTables_wrapper {
    padding-bottom: 0px;
  }
}

.ibox:last-of-type {
  margin-bottom: 50px;
}


$move-outline: 2px solid $sib-gold !default;
$moved-outline: 2px solid #888 !default;

@import '~datatables-rowreorder/css/rowReorder.dataTables.scss'; // '../../vendor/datatables-rowreorder/css/rowReorder.dataTables.scss';

@media (max-width: 767px) {
  #service-table_wrapper, #item-table_wrapper {
    .dataTables_scrollBody {
      max-height: none !important;
    }
  }

  .ibox:last-of-type {
    margin-bottom: 75px;
  }
}


table.dataTable tbody td.reorder {
  text-align: left;
}

.dtr-details {
  list-style: none;
  padding-left: 5px;

  .dtr-title {
    display: block;
    width: 100%;
    font-weight:  bold;
  }
  .dtr-data {
    padding-left: 20px;
    display: block;
  }
}