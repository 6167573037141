.dashboard {
  padding-bottom: 50px;

  .ibox:last-of-type {
    margin-bottom: 0px;
  }

  .grid-stack-item[data-gs-height="2"] {
    .ibox-content {
      height: 92px;
    }
  }
  .grid-stack-item[data-gs-height="4"] {
    .ibox-content {
      height: 252px;
    }
  }
  .grid-stack-item[data-gs-height="6"] {
    .ibox-content {
      height: 412px;
    }
  }
  .grid-stack-item[data-gs-height="8"] {
    .ibox-content {
      height: 572px;
    }
  }


  .grid-stack {
    >.grid-stack-item {
      >.grid-stack-item-content {
        overflow-y: hidden;
      }
    }
  }

  .ibox-title {
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ibox-content {
    overflow: auto;
    overflow-x: hidden;
  }

}

.widget-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .widget-header {
    flex-grow: 1;
    flex-shrink: 1;
  }
  .widget-header-1-3 {
    width: calc(25% - 10px);
  }
  .widget-header-2-3 {
    width: calc(50% - 10px);
  }
  .widget-header-3-3 {
    width: calc(25% - 10px);
  }

  .widget-header-1-2 {
    width: calc(70% - 10px);
  }

  .widget-header-2-2 {
    width: calc(30% - 10px);
  }
}

.dashboard-widget {
  // TodoList
  .todo-list {
    li {
      border-left: none;
      border-right: none;
      border-radius: 6px;
      margin-bottom: 8px;
      line-height: 26px;
      .label {
        font-size: 11px;
        margin-left: 0px;
        white-space: nowrap;
      }

      .fa-stack {
        width: 24px;
      }
    }

    .actions {
      display: inline-block;
    }
  }
}

.order-status-list {
  margin-left: -30px;
  margin-right: -30px;

  div:first-of-type {
    > ul > li > span {
      background-color: $sib-gold;
      color: #ffffff;
    }
  }
  div:last-of-type {
    > ul > li > span {
      border: 2px solid $green;
      color: $green;
    }
  }
  .order-card-list {
    padding: 10px 0;
    list-style: none;
    margin: 0;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;

    .order-card {
      &.new {
        border-color: $red;
        .card-hr-line {
          border-width: 1px 0;
        }
        .card-hr-line, .input-group-addon, .form-control {
          border-color: $red;
        }
      }

    }

    .order-card {
      background: #FAFAFB;
      border: 1px solid #e7eaec;
      margin: 0 0 20px 0;
      padding: 10px;
      border-radius: 2px;

      &:hover {
        background: #fff;
      }
      .card-hr-line {
        border-color: #edeff0;
        border-style: solid none none !important;
        border-width: 2px 0;
        margin-left: -10px;
        margin-right: -10px;
      }
      .input-group-addon {
        border-left: 0px;
      }
      .form-control {
        border-right: 0px;
      }
      .order-customer-name {
        a {
          color: $sib-gold;
        }
      }

      .order-status {
        margin-left: -10px;
        margin-right: -11px;
        margin-bottom: -11px;
        margin-top: 10px;
      }

      .order-line {
        font-size: 0.8em;
        margin-top: 5px;
        margin-bottom: 5px;

        .order-line-name {
          font-size: 0.8em;
          color: $sib-gold;
          font-weight: bold;
          text-transform: uppercase;
        }

        .order-start {
          font-style: italic;
        }
      }

      .order-villa {
        min-height: 20px;
        margin-top: 5px;
      }

      .order-price {
        margin-right: 30px;
        font-size: 1.2em;
      }

      &.booked, &.booked_fully {
        border-color: $green;
        .card-hr-line {
          border-width: 1px 0;
        }
        .card-hr-line, .input-group-addon, .form-control {
          border-color: $green;
        }
      }
    }

  }
}

.status-list {
  > div {
    padding: 0px;
    margin: 0px;
    padding-bottom: 50px;
    &:first-child {
      padding-left: 15px;
    }

    &:last-child {
      padding-right: 15px;
    }
  }

  > div {
    > ul {

      display: inline-block;
      list-style: none;
      width: 100%;
      background-color: $gray;
      font-size: 20px;
      padding-left: 0px;

      &.active {
        background-color: $sib-gold;
        color: #ffffff;
      }

      /** Main status **/
      > li {
        > span {
          text-align: center;
          display: block;
          height: 70px;
          line-height: 70px;
        }
        > ul {
          color: $text-color;
          padding-left: 0px;
          display: inline-block;
          background-color: #ffffff;
          margin-top: 20px;
          /** Sub item **/
          > li {
            font-size: 16px;
            &.active {
              color: #19b293;
              border-color: #19b293;
              background-color: #f3fbf9;
            }
            &.checked {
              color: $sib-gold;
              border-color: $sib-gold;
            }
          }
        }
      }
    }
  }

}
