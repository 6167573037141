.vue-form-generator {
  margin-left: -15px;
  margin-right: -15px;

  fieldset {
    legend {
      padding: 15px 15px 7px;
      min-height: 48px;
      background-color: $legend-background-color;
      border-style: solid none;
      border-width: 1px 0;
      border-color: $legend-border-color;
      color: $sib-gold;
      font-weight: bold;
      font-size: 13px;
      margin-bottom: 15px;
    }
  }

  .help-block.errors {
    color: $red;
  }
  label:empty {
    display: none;
  }
}

.fieldset-legend {
  text-transform: uppercase;
  padding: 15px 0px 7px;
  min-height: 48px;
  background-color: $legend-background-color;
  border-style: solid none;
  border-width: 1px 0;
  border-color: $legend-border-color;
  color: $sib-gold;
  font-weight: bold;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 15px;
}

.legend-header {
  color: $sib-gold;
  font-weight: bold;
}

h3.legend-header {
  font-size: 13px;
}

.form-hr-line {
  border-color: $legend-border-color;
  border-style: solid none none !important;
  border-width: 2px 0;
  margin-left: -20px;
  margin-right: -20px;
}

.form-hr-line.modal-hr-line {
  margin-left: -15px;
  margin-right: -15px;
}

.image-upload {
  .btn {
    color: #C9B05D;
  }
  .file-name {
    margin-left: 10px;
    display: inline-block;
  }
}
.image-upload > input
{
  display: none;
}

.chosen-container {
  margin-bottom: 15px;
}
.chosen-container-multi .chosen-choices {
  padding: 3px 6px;
  border-color: #e5e6e7;
  background-image: none;
}
.wizard > .content > .body .date ul.list-unstyled {
  list-style: none !important;
  padding-left: 0px;
}
select.form-control {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  background: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='#444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
  background-position: 100% 50%;
  background-repeat: no-repeat;
  padding-right: 25px;
  &.input-sm {
    line-height: 14px;
  }
}

.checkbox-vertical {
  .icheckbox_square-gold,
  .iradio_square-gold {
    display: block;
    margin-top: 6px;
  }
}

.card_wrapper {
  width: 100%;
  max-width: 350px;
  margin: 10px 10px 20px 0px;
}

.margin-bottom-row {
  margin-bottom: 15px;
}

.panel-default > .panel-heading > .panel-title .badge.label-danger {
  margin-left: 10px;
  background-color: $red;
}

.free_label {
  width: 100%;
}

.inline-modal-btn {
  background: none;
  border: none;
  color: #999;
  font-size: 11px;

  &:hover {
    color: #333;
    text-decoration: none;
  }
}

.nice-checkbox, .nice-radio {
  position:relative;
  padding-left:0;
  padding-top:6px;
  padding-bottom:6px;
  line-height: 1em;

  &.radio-inline, &.checkbox-inline { //default bootstrap style overschrijven
    padding-top:0px;
    padding-bottom:0px;
    + label {
      margin-bottom: 0px;
      vertical-align: middle;
    }
  }

  &.nice-checkbox-xs {
    input[type="checkbox"], input[type="radio"] {
      + label {
        height: 20px;
        line-height:20px; // hoogte van checkbox + borders
        padding-left: 20px;
        &:before {
          width:20px;
          height:20px;
        }
      }
    }

    input[type="checkbox"] {
      + label {
        &:before {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }

  &.nice-checkbox-xxs {
    input[type="checkbox"], input[type="radio"] {
      + label {
        height: 16px;
        line-height:16px; // hoogte van checkbox + borders
        padding-left: 16px;
        &:before {
          width:16px;
          height:16px;
        }
      }
    }

    input[type="checkbox"] {
      + label {
        &:before {
          font-size: 11px;
          line-height: 13px;
        }
      }
    }
  }
  input[type="checkbox"], input[type="radio"] {
    display:none;

    + label {
      display:block;
      height:22px;
      padding-left:30px;
      line-height:22px; // hoogte van checkbox + borders
      cursor:pointer;
      margin-bottom:0;

      &:before {
        padding-top:1px; // omdat de border 1px groter wordt
        width:22px;
        height:22px;
        position:absolute;
        left:0;
        text-align:center;
        background-color: #ffffff;
        border:1px solid $sib-gold;
        content:"";
        transition:background-color .3s, border-color .3s;
      }

      &:hover {
        &:before {
          border-width:2px;
          padding-top:0;
        }
      }
    }

    &:checked {
      + label {
        &:before {
          background-color:$sib-gold;
          border-color: $sib-gold;
        }
      }
    }

    &:disabled {
      + label {
        &:before {
          background-color: #eee;
          border-color: #eee;
        }
      }
    }
  }

  input[type="checkbox"] {

    &:checked {
      + label {
        &:before {
          content:"\f00c";
        }
      }
    }
    + label {
      &:before {
        font-family: FontAwesome;
        font-size:14px;
        line-height:18px;
        color:#ffffff;
      }
    }
  }

  input[type="radio"] {
    &:checked {
      + label {
        &:before {
          content:"\f00c";
        }
      }
    }
    + label {
      &:before {
        font-family: FontAwesome;
        font-size:11px;
        line-height:20px;
        color:#ffffff;
        border-radius:50%;
      }
    }
  }
}

.input-xs {
  height: 22px;
  padding: 2px 5px;
  font-size: 12px;
  line-height: 1.5; /* If Placeholder of the input is moved up, rem/modify this. */
  border-radius: 3px;
}


.vue-form-wizard {
  margin-bottom: 50px;
}
.multiselect__fullwidth {
  .multiselect {
    .multiselect__tags {
      .multiselect__tag {
        width: 100%;
        span {
          word-wrap: break-word;
          width: 100%;
          white-space: initial;
        }
      }
    }
  }
}
.multiselect {
  &.multiselect--active {
    z-index: 5;
  }
  min-height: 32px !important;

  .multiselect__spinner {
    &:before, &after {
      border-color: $sib-gold transparent transparent;
    }
  }
  .multiselect__option--highlight {
    background: $sib-gold;

    &:after {
      background: $sib-gold;
    }
  }
  .multiselect__tags {
    padding: 6px 40px 6px 8px;
    min-height: 32px;
    border-radius: 0px;

    .multiselect__input, .multiselect__single{
      padding: 0px 0 0 5px;
      margin-bottom: 0px;
      border-radius: 0px;
    }

    .multiselect__tag {
      border-radius: 0px;
      background-color: $sib-gold;
      margin-top: 2px;
      margin-bottom: 2px;
      margin-right: 4px;

      .multiselect__tag-icon {
        &:focus, &:hover {
          background-color: $sib-gold;
        }

        &:after {
          color: white;
        }
      }

    }
  }
  .multiselect__select {
    height: 32px;
    width: 32px;
  }
  .multiselect__content-wrapper {
    z-index: 10;
    .multiselect__content {
      z-index: 10;
      li {
        z-index: 10;
      }
    }
  }
}

.pac-container {
  z-index: 2051;
}

.field-multi-select {
  .multiselect,
  .multiselect__input,
  .multiselect__single {
    font-size: 14px;
  }
}