
.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .cards-section {
    flex: 0 1 calc(25% - 20px);

    .card-section-title {
      font-size: 20px;
      text-align: center;
      display: block;
      height: 70px;
      line-height: 70px;
      background-color: #f3f3f4;

      margin-left: -15px;
      margin-right: -15px;
      &.active {
        background-color: $sib-gold;
        color: #ffffff;
      }
    }

    .card-list {
      padding: 10px 0;
      list-style: none;
      margin: 0;
      width: 100%;
      color: $text-color;
      background-color: #ffffff;
      margin-top: 20px;

      .card {
        background: #FAFAFB;
        border: 1px solid #e7eaec;
        margin: 0 0 20px 0;
        padding: 10px;
        border-radius: 2px;
        font-size: 16px;
        &.active {
          color: #19b293;
          border-color: #19b293;
          background-color: #f3fbf9;
        }
        &.checked {
          color: $sib-gold;
          border-color: $sib-gold;
        }
        &:hover {
          background: #fff;
        }
        .card-hr-line {
          border-color: #edeff0;
          border-style: solid none none !important;
          border-width: 2px 0;
          margin-left: -10px;
          margin-right: -10px;
        }
        .input-group-addon {
          border-left: 0px;
        }
        .form-control {
          border-right: 0px;
        }
      }

    }
  }
}

@media (max-width: 1199px) {
  .cards-container {
    .cards-section {
      flex: 0 1 calc(50% - 15px);
    }
  }
}

@media (max-width: 768px) {
  .cards-container {
    .cards-section {
      flex: 0 1 100%;
    }
  }
}

// Order cards
.cards-container.order-cards {
  .cards-section {
    &:last-of-type {
      .card-section-title {
        border: 2px solid $green;
        color: $green;
      }
    }
    .order-card-list {
      .card {
        &.new {
          border-color: $red;
          .card-hr-line {
            border-width: 1px 0;
          }
          .card-hr-line, .input-group-addon, .form-control {
            border-color: $red;
          }
        }
      }

      .card {
        .order-villa {
          display: flex;
          justify-content:flex-end;
          .villa-codes {
            display: flex;
            margin-right: auto;
            padding-right: 10px;
          }
        }
        .order-customer-name {
          display: flex;
          justify-content: flex-end;
          a {
            color: $sib-gold;
            margin-right: auto;
          }
        }

        .order-status {
          margin-left: -10px;
          margin-right: -11px;
          margin-bottom: -11px;
          margin-top: 10px;
        }

        .order-line {
          font-size: 0.8em;
          margin-top: 5px;
          margin-bottom: 5px;

          .order-line-name {
            font-size: 0.8em;
            color: $sib-gold;
            font-weight: bold;
            text-transform: uppercase;
          }

          .order-start {
            font-style: italic;
          }
        }

        .order-villa {
          min-height: 20px;
          margin-top: 5px;
        }

        .order-price {
       //   margin-right: 30px;

          font-size: 1.2em;
        }

        &.booked, &.booked_fully {
          border-color: $green;
          .card-hr-line {
            border-width: 1px 0;
          }
          .card-hr-line, .input-group-addon, .form-control {
            border-color: $green;
          }
        }
      }
    }
  }
}

.cards-container.booking-status-cards {
  .cards-section {
    flex: 0 1 calc(20% - 20px);
    .card-list {
      margin-top: 0px;

      .card {
        margin-bottom: 5px;
      }
    }
  }
}
.cards-container.order-status-cards {
  .cards-section {
    .card-list {
      margin-top: 0px;

      .card {
        margin-bottom: 5px;
      }
    }
  }
}