.panel-default:not(.dataTables_processing) {
  border-color: $sib-gold;
  border-bottom: none;
}
.dataTables_processing {
  border-color: $sib-gold;
}
.panel-default > .panel-heading, .panel-group .panel-default > .panel-heading {
  color: $sib-gold;
  background-color: #ffffff;
  border-color: $sib-gold;
  height: 50px;
  border-radius: 5px;
  border-bottom: 1px solid $sib-gold;
}

.panel-title {
  text-transform: none;
  font-size: 14px;
  line-height: 30px;

  i.fa {
    font-size: 1.5em;
  }
}

.panel-body {
  border-bottom: 1px solid $sib-gold;

  .fieldset-legend {
    text-transform: uppercase;
    margin-left: -15px;
    margin-right: -15px;
  }
}

.panel-default > .panel-heading.active {
  background-color: #fcfbf7;
}


.panel-default:last-of-type {
  margin-bottom: 50px;
}

.panel-group .panel-heading + .panel-collapse > .panel-body, .panel-group .panel-heading + .panel-collapse > .list-group {
  border-top: none;
}

@media (max-width: 767px) {

  .panel-default:last-of-type {
    margin-bottom: 75px;
  }
}

.order-info-header, .booking-info-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  div {
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 10px;

    &:last-child {
      margin-right: 0px;
    }
    &.header-buttons {
      display: flex;
      a {
        margin-right: 5px;
        flex-grow: 1;
        flex-shrink: 1;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}