.clear-view {
  background-color: $sib-gold;

  .clear-logo {
    margin-bottom: 50px;
  }
}

@media (max-width: 768px) {
  body.clear-view:not(.mini-navbar) {
      background-color: $sib-gold;
  }
}