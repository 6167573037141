.modal-dialog {
  .modal-header {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: $ibox-title-bg;
    border-color: $ibox-title-border-color;
    border-image: none;
    border-style: none none solid;
    border-width: 0 0 2px;
    color: $ibox-title-color;
    margin-bottom: 0;
    padding: 15px 15px 7px;
    min-height: 48px;

    .close {
      color: #FFFFFF;
      opacity: 1;
    }
  }

  .modal-body {
    background-color: $ibox-content-bg;
    color: inherit;
    padding: 0px 0px 20px 0px;

    border-color: $border-color;
    border-image: none;
    border-style: solid solid none;
    border-width: 1px 0;

    .ibox-content {
      border-top: none;

      .form-hr-line {
        margin-left: -15px;
        margin-right: -15px;
      }
      form > div.vue-form-generator > fieldset:first-child {
        > legend {
          position: relative;
          top: -15px;
          margin-bottom: 0px;
        }

      }
    }

    .bootbox-body {
      padding: 20px 20px 0 20px;
    }
  }

  .modal-footer {
    color: inherit;
    border-top: 1px solid $border-color;
    font-size: 90%;
    background: #ffffff;
    padding: 10px 15px;

    .btn-success {
      background-color: $sib-gold;
      border-color: $sib-gold;
    }

    .btn-danger {
      color: $sib-black;
      background-color: transparent;
      border-color: $sib-black;
    }
  }

  &.modal-lg {
    width: 90%;
  }
}