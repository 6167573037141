.informationlist, .shoppinglist {

  label {
    max-width: 80%;
  }
  .shoppinglist-direct-services, .shoppinglist-subcategories, .informationlist-direct-services, .informationlist-subcategories {
    padding: 0px;
    margin: 0px;
    margin-bottom: 10px;
    display: table;
    width: 100%;
    list-style: none;
    > li {
      display: table-cell;
      margin-bottom: 5px;
      a {
        display: block;
      }
      ul {
      }
    }

    .informationlist-subcategory-name, .shoppinglist-subcategory-name {
      font-size: 1.2em;
      color: $sib-gold;
      margin-top: 15px;
      margin-bottom: 10px;
      display: block;
    }

  }


  .informationlist-category-footnote, .shoppinglist-category-footnote {
    margin-top: 20px;
  }

  .services-list {
    list-style: none;
    padding: 0px;
    margin-bottom: 10px;
    > div.row, li {
      margin-bottom: 5px;
    }
  }
  .fieldset-collapse {
    padding-bottom: 20px;
  }
}