.autocomplete-suggestions {
  background: #FFF;
  overflow: auto; }
.autocomplete-suggestion {
  padding: 8px 5px;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid #999;
  border-top: none;
}
.autocomplete-selected { background: #F0F0F0; }
.autocomplete-suggestions strong { font-weight: normal; color: $sib-gold; }
.autocomplete-group { padding: 2px 5px; }
.autocomplete-group strong { display: block; border-bottom: 1px solid #000; }

.autocomplete-link {
  border-left: none;
  a {
    color: $text-color;
  }
}